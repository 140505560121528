// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("/opt/build/repo/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-en-tsx": () => import("/opt/build/repo/src/pages/about-us.en.tsx" /* webpackChunkName: "component---src-pages-about-us-en-tsx" */),
  "component---src-pages-about-us-nl-tsx": () => import("/opt/build/repo/src/pages/about-us.nl.tsx" /* webpackChunkName: "component---src-pages-about-us-nl-tsx" */),
  "component---src-pages-faq-en-tsx": () => import("/opt/build/repo/src/pages/faq.en.tsx" /* webpackChunkName: "component---src-pages-faq-en-tsx" */),
  "component---src-pages-faq-nl-tsx": () => import("/opt/build/repo/src/pages/faq.nl.tsx" /* webpackChunkName: "component---src-pages-faq-nl-tsx" */),
  "component---src-pages-index-en-tsx": () => import("/opt/build/repo/src/pages/index.en.tsx" /* webpackChunkName: "component---src-pages-index-en-tsx" */),
  "component---src-pages-index-nl-tsx": () => import("/opt/build/repo/src/pages/index.nl.tsx" /* webpackChunkName: "component---src-pages-index-nl-tsx" */),
  "component---src-pages-privacy-statement-en-tsx": () => import("/opt/build/repo/src/pages/privacy-statement.en.tsx" /* webpackChunkName: "component---src-pages-privacy-statement-en-tsx" */),
  "component---src-pages-privacy-statement-nl-tsx": () => import("/opt/build/repo/src/pages/privacy-statement.nl.tsx" /* webpackChunkName: "component---src-pages-privacy-statement-nl-tsx" */),
  "component---src-pages-seminar-middle-east-en-tsx": () => import("/opt/build/repo/src/pages/seminar-middle-east.en.tsx" /* webpackChunkName: "component---src-pages-seminar-middle-east-en-tsx" */),
  "component---src-pages-seminar-middle-east-nl-tsx": () => import("/opt/build/repo/src/pages/seminar-middle-east.nl.tsx" /* webpackChunkName: "component---src-pages-seminar-middle-east-nl-tsx" */),
  "component---src-pages-seminar-russia-en-tsx": () => import("/opt/build/repo/src/pages/seminar-russia.en.tsx" /* webpackChunkName: "component---src-pages-seminar-russia-en-tsx" */),
  "component---src-pages-seminar-russia-nl-tsx": () => import("/opt/build/repo/src/pages/seminar-russia.nl.tsx" /* webpackChunkName: "component---src-pages-seminar-russia-nl-tsx" */),
  "component---src-pages-seminar-west-africa-en-tsx": () => import("/opt/build/repo/src/pages/seminar-west-africa.en.tsx" /* webpackChunkName: "component---src-pages-seminar-west-africa-en-tsx" */),
  "component---src-pages-seminar-west-africa-nl-tsx": () => import("/opt/build/repo/src/pages/seminar-west-africa.nl.tsx" /* webpackChunkName: "component---src-pages-seminar-west-africa-nl-tsx" */),
  "component---src-pages-thank-you-en-tsx": () => import("/opt/build/repo/src/pages/thank-you.en.tsx" /* webpackChunkName: "component---src-pages-thank-you-en-tsx" */),
  "component---src-pages-thank-you-nl-tsx": () => import("/opt/build/repo/src/pages/thank-you.nl.tsx" /* webpackChunkName: "component---src-pages-thank-you-nl-tsx" */)
}

